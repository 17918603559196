<template>
  <v-app>
    <v-container style="max-width: 600px;">
      <v-overlay v-model="showProgress" class="justify-center align-center">
        <v-progress-circular
          indeterminate
          size="60"
          width="5"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <!-- Image -->
      <v-row align="center" justify="center" >
          <v-col cols="auto">
            <v-img
              v-if="!inReview"
              :src="tblItems[0].saImageUrl + sasToken"
              height="340"
              width="340"
            />
          </v-col>
      </v-row>
      <!-- Image REVIEW -->
      <v-row v-if="inReview" align="center" justify="center" >
        <h3>Review Incorrect Responses</h3>
      </v-row>
      <v-row align="center" justify="center" >
          <v-col cols="auto">
            <v-img
              v-if="inReview"
              :src="tblItemsReview[currentIndex].saImageUrl + sasToken"
              height="340"
            />
          </v-col>
      </v-row>
      <!-- AI Conversation -->
      <v-row v-if="!inReview">
        <v-col>
          <v-responsive v-if="activeChat" height="auto">
            <v-card flat>
              <v-card-text class="flex-grow-1 overflow-y-auto">
                <template v-for="(msg) in messagesAll">
                  <div :class="{ 'd-flex flex-row-reverse': msg.me }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }">
                          <v-chip
                            :color="msg.me ? 'orange darken-3' : 'blue darken-3'"
                            dark
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-2">
                              {{ msg.content }}
                          </v-chip>
                        </v-hover>
                      </template>
                    </v-menu>
                  </div>
                </template>
              </v-card-text>
              <v-card-text class="flex-shrink-1" v-if="showResponseSection">
                  <v-text-field
                  v-model="messageForm.content"
                  label="Enter your response..."
                  type="text"
                  no-details
                  variant="outlined"
                  :append-outer-icon="messageForm.content ? 'mdi-check-circle' : null"
                  @keyup.enter="messageForm.content && callAzureOpenAI"
                  @click:append-outer="callAzureOpenAI"
                  hide-details
                />
              </v-card-text>
            </v-card>
          </v-responsive>
        </v-col>
      </v-row>
      <!-- AI Conversation REVIEW -->
      <v-row v-if="inReview">
        <v-col>
          <v-responsive v-if="activeChat" height="auto">
            <v-card flat>
              <v-card-text class="flex-grow-1 overflow-y-auto">
                <template v-for="(msg) in currentMessagesAll">
                  <div :class="{ 'd-flex flex-row-reverse': msg.me }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }">
                          <v-chip
                            :color="msg.me ? 'orange darken-3' : 'blue darken-3'"
                            dark
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-2">
                              {{ msg.content }}
                          </v-chip>
                        </v-hover>
                      </template>
                    </v-menu>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-responsive>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>  
        <v-col cols="auto">
          <v-btn color="primary" class="mt-5 mr-1" v-if="messagesAll.length > 2" @click="nextImage">Next</v-btn>
          <v-btn color="error" class="mt-5 mr-1" v-if="showReset" @click="reset">Reset</v-btn>
        </v-col>
        <v-col cols="auto" class="mt-5">
          <v-btn color="primary" class="mr-1" @click="reviewItems" v-if="tblItems[0].id === '0000' && tblItemsReview.length > 0 && !inReview">Review</v-btn>
          <v-btn color="primary" class="mr-1" v-if="inReview" @click="prevReview" :disabled="currentIndex === 0">Prev</v-btn>
          <v-btn color="primary" v-if="inReview" @click="nextReview" :disabled="currentIndex === tblItemsReview.length - 1">Next</v-btn>
        </v-col>
        <v-col cols="auto" v-if="!inReview">
          <v-progress-circular
            v-if="percentScore > 0"
            :rotate="360"
            :size="55"
            :width="5"
            :value="percentScore"
            color="teal"
          >
            {{ percentScore }}%
          </v-progress-circular>
        </v-col>
        <v-col cols="3">
          <v-select v-if="!run4FirstTime"
            class="mt-5 ml-1"
            :items="itemLevels"
            label="Level"
            outlined
            v-model="level"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>  
        <v-btn color="error" v-if="showReport" @click="report">Report an Issue</v-btn>
      </v-row>
    </v-container>
    <ErrorPage v-if="showErrorPage" />
    <Confirm
      v-if="showConfirm"
      :confirmItem="reportItem"
      :confirmMsg="reportMsg"
      :confirmExer="confirmExer"
      @OK="okay"
      @cancel="showConfirm = false"/>
  </v-app>
</template>

<script>
// Spot the Difference
import axios from 'axios';
import ErrorPage from "@/components/ErrorPage.vue"
import Confirm from "@/components/Confirm.vue"

export default {
  data() {
    return {
      run4FirstTime: true,
      showResponseSection: false,
      currentIndex: 0,  
      nextToken: null,
      currentToken: null,
      morePages: true,
      tblItems: [{id: "", level: "", imgID: "", saImageUrl: "", saImageUrlwToken: "", description: "", answer: "", deleteVote: 0, status: "", misc1: "", misc2: ""}],

      sasToken: process.env.VUE_APP_SA_SASTOKEN,
      chatURL: process.env.VUE_APP_CHAT_URL,
      chatApiKey: process.env.VUE_APP_CHAT_APIKEY,
      
      itemLevels: ["1", "2", "3"],
      level: "1",

      prompt: '',

      showProgress: false,
      activeChat: 1,
      messagesAll: [],
      messageForm: {
        content: "",
        me: true
      },
      reqBody: {
        messages: [],
        temperature: 0.2,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
      
      rightAnswers: 0,
      totalQuestions: 0,
      percentScore: 0,

      showErrorPage: false,
      showConfirm: false,

      tblItemsReview: [],
      inReview: false,
      currentIndex: 0,

      reportItem: [],
      reportMsg: "",
      showReport: false,
      confirmExer: "exer2",

      systemMsg: "You are a speech therapist. With sequencing events exercise, you're goal is to help your patient figure out what happens next. Ask exactly this phrase, 'What should happen next?'. If my answer is wrong, give me a one sentence response. The right answer is ",
    };
  },
  components: {
    ErrorPage,
    Confirm
  },
  created(){
    this.showProgress = true
    this.setLevel();
  },
  computed: {
      currentStorageLevel(){
        return "Exer2Level" + this.level
      },
      currentStorageLevelReview(){
        return "Exer2Level" + this.level + "Review"
      },
      currentMessagesAll(){
        return this.tblItemsReview[this.currentIndex].messagesAll
      },
      showReset(){
        if(this.tblItems[0].id === '0000' || this.inReview){
          return true;
        }
      }
    }, 
  methods: {
    nextImage(){
        this.tblItems.shift(0,1)
        this.showProgress = true
        this.messagesAll = []
        this.reqBody.messages = []
        this.firstCall2OpenAI()
        this.currentToken = this.tblItems[0].currentToken
        this.retrieveItems()
        this.showReport = false
    },  
    async retrieveItems(){
      // if there's no more images to retrieve add the ending item or don't retrieve anymore
      // 0000 is the id indicating that there aren't anymore items to show
      if(!this.morePages){
        // if last image is 0000
        if(this.tblItems[0].id === "0000"){
          this.showResponseSection = false
          this.showProgress = false
          this.saveReview2Local(this.level)
        }
        const endIndex = this.tblItems.length - 1
        if(this.tblItems[endIndex].id === "0000"){
          this.save2local()
          return;
        } else {
          this.tblItems.push({id: "0000", level: "0", imgID: "0000", saImageUrl: "https://sandesa.blob.core.windows.net/sandesac/0000.png?", saImageUrlwToken: "", description: "", answer: "", deleteVote: 0, status: "", misc1: "", misc2: ""})
          this.save2local()
          return;
        }
        
      }
      this.showProgress = true
      // Set initial variables  
      const variables = {  
        first: 1,
        after: this.nextToken,
        filtervar: this.level   
      };  
      const query = `
        query Exer2Images($first: Int!, $after: String, $filtervar: String){
          exer2images (first: $first, after: $after, filter: { level : { eq: $filtervar}}){
            items {
              id,
              level,
              imgID,
              saImageUrl,
              description,
              answer,
              deleteVote,
              status,
              misc1,
              misc2
            }
            endCursor
            hasNextPage
          }
        }`;
      const endpoint = "/data-api/graphql";

      try{
      const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({query, variables})
      })
      const result = await response.json();
      const preTblItems = result.data.exer2images.items.map(item => ({
        id: item.id,
        level: item.level,
        imgID: item.imgID,
        saImageUrl: item.saImageUrl,
        saImageUrlwToken: item.saImageUrl + this.sasToken,
        description: item.description,
        answer: item.answer,
        deleteVote: item.deleteVote,
        status: item.status,
        misc1: item.misc1,
        misc2: item.misc2,
        currentToken: this.nextToken
      }))

      this.tblItems = this.tblItems.concat(preTblItems)

      this.morePages = result.data.exer2images.hasNextPage
      this.nextToken = result.data.exer2images.endCursor
      
      this.showProgress = false

      } catch (err) {
        this.showErrorPage=true
        this.showReset=true
        this.showProgress=false
        console.log(err)
      }
      
      if(!this.tblItems[0].id){
        this.tblItems.shift(0,1)
      }
      this.save2local()
    },  
    postMessage(botContentMsg){
      this.messagesAll.push({content: this.messageForm.content, me: true}) //me
      this.messageForm.content = ""
      this.messagesAll.push({content: botContentMsg, me: false})
      this.reqBody.messages.push({role: "system", content: botContentMsg})
    },
    async firstCall2OpenAI(){
      if(this.inReview){
        return;
      }
      if(this.tblItems[0].id === "0000"){
          return;
        }
      if(this.messagesAll.length < 2){
        this.messagesAll = []
      }
      this.showResponseSection = true
 
      this.reqBody.messages.push(
        {
          role: "system",
          content: this.systemMsg + this.tblItems[0].answer
        },      
        {
          role: "user",
          content: "ask"
        }
      )

      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        const botMsg = res.data.choices[0].message.content
        this.messagesAll.push({content: botMsg, me: false})
        this.reqBody.messages.push({role: "assistant", content: botMsg })
        this.showProgress = false
      } catch (error) {
        this.showErrorPage=true
        this.showReset=true
        this.showProgress=false
        console.log(error);
      }
    },
    async callAzureOpenAI() {
      this.showProgress = true
      // Execute when SEND is clicked
      this.reqBody.messages.push({role: "user", content: this.messageForm.content})
      console.log(this.reqBody.messages)

      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        this.postMessage(res.data.choices[0].message.content)
        this.showProgress = false
        this.showResponseSection = false
      } catch (error) {
        this.showErrorPage=true
        this.showReset=true
        this.showProgress=false
        console.log(error);
      }
      this.rightOrWrong()
    },
    async rightOrWrong(){
      this.showProgress = true
      this.reqBody.messages.push({role: "user", content: "did I get it right?"})
      try {
        const res = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        const outcome = res.data.choices[0].message.content

        if(outcome.toLowerCase().includes("yes")){
          this.rightAnswers += 1
        } else {
          // what happens after wrong answer
          this.tblItemsReview.push({saImageUrl: this.tblItems[0].saImageUrl, messagesAll: this.messagesAll})
        }
        this.totalQuestions += 1
        this.percentScore = Math.round(((this.rightAnswers / this.totalQuestions) * 100) * 10) / 10

        this.reportItem = [{id: this.tblItems[0].id, saImageUrl: this.tblItems[0].saImageUrl, messagesAll: this.messagesAll}]

        this.save2local()
        this.showProgress = false
        this.showReport=true

      } catch (error) {
        this.showErrorPage=true
        this.showReset=true
        console.log(error);
      }
    },
    saveReview2Local(level){
      const paramsReview = {
        ptblItemsReview: this.tblItemsReview
      }
      localStorage.setItem("Exer2Level" + level + "Review", JSON.stringify(paramsReview))
    },
    save2local(){
      const params = {
        pNextToken: this.nextToken,
        pCurrentToken: this.currentToken,
        ptblItems: this.tblItems,
        pMessagesAll: this.messagesAll,
        pRightAnswers: this.rightAnswers,
        pTotalQuestions: this.totalQuestions,
        pPercentScore: this.percentScore,
        pMorePages: this.morePages,
        pShowResponseSection: this.showResponseSection,
        pInReview: this.inReview,
        pCurrentIndex: this.currentIndex,
        pShowReport: this.showReport
      }

      localStorage.setItem(this.currentStorageLevel, JSON.stringify(params))
    },
    saveLevel2local(){
      const paramsLevel = {
        pLevel: this.level
      }
      localStorage.setItem('selectedLevel', JSON.stringify(paramsLevel))
    },
    setLevel(){
      const paramsUsedLevel = JSON.parse(localStorage.getItem('selectedLevel'))
      if(paramsUsedLevel){
        this.level=paramsUsedLevel.pLevel
      }
      this.restoreFromLocal()
      this.restoreReviewFromLocal()
    },
    async restoreFromLocal(){
      this.showProgress = true
      const paramsUsed = JSON.parse(localStorage.getItem(this.currentStorageLevel))

      // if there's something stored locally
      if (paramsUsed){
        this.tblItems = paramsUsed.ptblItems;
        this.nextToken = paramsUsed.pNextToken
        this.currentToken = paramsUsed.pCurrentToken;
        this.messagesAll = paramsUsed.pMessagesAll;
        this.run4FirstTime = paramsUsed.pRun4FirstTime
        this.rightAnswers = paramsUsed.pRightAnswers
        this.totalQuestions = paramsUsed.pTotalQuestions
        this.percentScore = paramsUsed.pPercentScore,
        this.morePages = paramsUsed.pMorePages,
        this.showResponseSection = paramsUsed.pShowResponseSection
        this.inReview = paramsUsed.pInReview
        this.currentIndex = paramsUsed.pCurrentIndex
        this.showReport = paramsUsed.pShowReport

        if(this.messagesAll.length < 2){
          this.firstCall2OpenAI()
        }

        this.reportItem = [{id: this.tblItems[0].id, saImageUrl: this.tblItems[0].saImageUrl, messagesAll: this.messagesAll}]

            // if app is launched for first time
      } else if (this.run4FirstTime){
        this.messagesAll=[]
        for (let i = 0; i < 4; i++) {      
          await this.retrieveItems()
        }
        this.firstCall2OpenAI()

      // if level is changed and nothing saved locally
      } else {
        this.tblItems = [{id: "", level: "", imgID: "", saImageUrl: "http://", saImageUrlwToken: "http://", description: "description", answer: "", deleteVote: 0, status: "", misc1: "", misc2: ""}]
        this.nextToken = null
        this.messagesAll=[]
        this.morePages=true
        this.reqBody.messages=[]
        this.rightAnswers = 0
        this.totalQuestions = 0
        this.percentScore = 0
        // if retrieving new data, turn off in Review
        this.inReview = false
        for (let i = 0; i < 4; i++) {      
          await this.retrieveItems()
        }
        this.firstCall2OpenAI()
      } 
      this.showProgress = false
      this.run4FirstTime = false
    },
    restoreReviewFromLocal(){
      this.tblItemsReview=[]
      const paramsUsedReview = JSON.parse(localStorage.getItem(this.currentStorageLevelReview))
      if(paramsUsedReview){
        this.tblItemsReview = paramsUsedReview.ptblItemsReview
      } 
    },
    reset(){
        localStorage.removeItem(this.currentStorageLevel)
        localStorage.removeItem(this.currentStorageLevelReview)
        this.nextToken = null
        this.currentToken = ""
        this.messagesAll = []
        this.rightAnswers = 0
        this.totalQuestions = 0
        this.percentScore = 0
        this.morePages = true
        this.showResponseSection = false
        this.tblItemsReview = []
        this.currentIndex = 0
        this.restoreFromLocal()
        this.save2local()
        this.inReview = false
        this.showReport = false

    },
    reviewItems(){
      this.inReview = true
      this.showResponseSection = false
      this.showReport = false
      this.save2local()
    },
    nextReview(){
      this.currentIndex++
      this.save2local()
    },
    prevReview(){
      this.currentIndex--
      this.save2local()
    },
    report(){
      this.reportMsg="report"
      this.showConfirm=true
    },
    okay(){
      this.showConfirm = false
      this.showReport = false
    }
  },
  watch: {  
    messagesAll(){
      if(this.messagesAll.length > 2){
        this.save2local()
        this.showResponseSection = false
      }
    },
    level(newVal, oldVal){
      this.saveReview2Local(oldVal)
      this.saveLevel2local()
      this.restoreFromLocal()
      this.restoreReviewFromLocal()
    }
  },  
};


</script>
<style>
.v-progress-circular {
  margin: 1rem;
}
</style>