<template>
  <v-app>
    <v-system-bar color="blue darken-3"></v-system-bar>
    <v-app-bar color="primary" dark class="flex-grow-0">
      <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title @click="$router.push('/')">

        <span class="font-weight-light white--text" style="font-size: 25px">Therapy</span>
        <span style="margin-left: 10px"></span>
        <span class="font-weight-light white--text" style="font-size: 25px">Images</span>
        <span style="margin-left: 10px"></span>
        <span class="white--text" style="font-size: 15px">by</span>
        <span style="margin-left: 10px"></span>
        <span class="bold white--text" style="font-size: 25px">AI</span>

      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="yellow"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link to="contactus">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-if="user">mdi-account-circle</v-icon>
            <v-icon v-if="!user">mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>

        <v-list class="blue darken-3" dark>

          <v-list-item v-if="!user" to="/login" link>
                        <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon><v-list-item-title>Login</v-list-item-title>

          </v-list-item>
          <v-list-item v-if="user" link>
                        <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon><v-list-item-title @click="logout">Logout</v-list-item-title>
          </v-list-item>
          <v-list-item to="/instructions" link>
            <v-list-item-icon>
              <v-icon>mdi-sign-direction</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Instructions</v-list-item-title>
          </v-list-item>
          <v-list-item to="/mteams" link :disabled="!user">
            <v-list-item-icon>
              <v-icon :disabled="!user">mdi-microsoft-teams</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Teams</v-list-item-title>
          </v-list-item>
          <v-list-item to="/addwarning" link :disabled="!user">
            <v-list-item-icon>
              <v-icon :disabled="!user">mdi-account-multiple-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Add Team</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="primary"
      dark
      temporary
      absolute
    >
      <v-list>
        <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Exercise Decks
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
        <v-list-item
          link
          v-for="(item, i) in navList"
          :key="i"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    drawer: false,
    navList: [
      // { text: "Search", icon: "mdi-magnify-expand", route: "/" },
      { text: "What is different?", icon: "mdi-magnify-expand", route: "exer1" },
      { text: "What happens next?", icon: "mdi-skip-next-outline", route: "exer2" },
      { text: "Coming Soon", icon: "mdi-new-box", route: "exer2" },
      { text: "Coming Soon", icon: "mdi-new-box", route: "exer2" },
      // { text: "About Us", icon: "mdi-information", route: "aboutus" },
      // {
      //   text: "Suggestions",
      //   icon: "mdi-comment-quote-outline",
      //   route: "suggestion",
      // },
    ],
  }),
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  }
};
</script>
