<template>  
    <v-app>  
      <v-container>  
        <v-row justify="center">  
          <v-col cols="12" sm="8" md="6">  
            <v-img src="under-construction.jpeg" class="mt-10"></v-img>  
          </v-col>  
        </v-row>  
      </v-container>  
    </v-app>  
  </template>  
    
  <script>  
    export default {  
      // Component code  
    }  
  </script>  
  