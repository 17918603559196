<template>
    <div>
      <v-row justify="space-around">
        <v-col cols="auto">
          <v-dialog v-model="dialogConfirmation" persistent max-width="600">
            <v-card>
              <v-toolbar color="primary" dark>Confirm</v-toolbar>
              <v-card-text>
                <div class="text-h7 mx-12 my-6">
                  {{ msg }}
                </div>
              </v-card-text>

              <v-textarea
                v-if="showDescription"
                class="mx-12"
                outlined
                label="Add other issues to report..."
                v-model="description"
              ></v-textarea>

              <v-card-actions class="justify-center">
                <v-btn class="mb-5 mr-6" color="primary" @click="closeCancel"
                  >Cancel</v-btn
                >
                <v-btn class="mb-5 mr-6" color="primary" @click="takeAction"
                  >{{ btnLabel }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-dialog v-model="showProgress">
        <div class="text-center" justify="center" align="center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: ["confirmItem","confirmMsg","confirmExer"],
    data() {
      return {
        dialogConfirmation: true,
        showProgress: false,
        showCancel: true,
        description: "",
        // confirmItem: [{id: "1"}],
        // confirmMsg: "report",
      };
    },
    computed: {
      msg: {
        get() {
          if (this.confirmMsg === "report") {
            return (this.msg =
              "Your current chat with AI will be reported to the Development Team. Is there anything else you would like to report?");
          } 
          // else if (this.itemFilteredTeams.selectedInfo === "Tryout") {
          //   return (this.msg =
          //     "Redirecting to a page with tryout information or a tryout signup page...");
          // } else if (this.itemFilteredTeams.selectedInfo === "Pickup") {
          //   return (this.msg =
          //     "Redirecting to a page with pickup information...");
          // } else if (this.itemFilteredTeams.selectedInfo === "Open Practice"){
          //   return (this.msg =
          //     "Please reach out to the coach via email/text (if available) or through their website for details...");
          // } else {
          //   return (this.msg = 
          //     "Please use an iPhone or Android device to get in touch with the coach...");
          // }
        },
        set() {},
      },
      btnLabel(){
        if (this.confirmMsg === "report") {
          return "Send"
        }
      },      
      showDescription(){
        if (this.confirmMsg === "report") {
          return true
        }
      },
  },
  methods: {
      closeCancel() {
        this.$emit("cancel");
      },
      takeAction(){
        if (this.confirmMsg === "report"){
          this.sendReport()
        }
      },
      async sendReport(){
        this.showProgress = true
        const data = {
          id:`${Date.now()}`,
          imgID: this.confirmItem[0].id,
          exer: this.confirmExer,
          saImageUrl: this.confirmItem[0].saImageUrl,
          description: this.description,
          messagesAll: JSON.stringify(this.confirmItem[0].messagesAll)
        };
        
        const gql1 = `
        mutation create($item: CreateproblemitemInput!) {
          createproblemitem(item: $item) {
              id
              imgID
              exer
              saImageUrl
              description
              messagesAll
            }
          }`;
        
        const query = {
          query: gql1,
          variables: {
            item: data
          } 
        };
        
        const endpoint = "/data-api/graphql";
        try {
          await fetch(endpoint, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(query)
          });
        } catch (error) {
          console.log(error);
        }
        this.$emit("OK");
        this.showProgress = false
      }
    },
};
  </script>
  