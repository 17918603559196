import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VContainer,[_c(VRow,{staticClass:"mt-4",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('a',{attrs:{"href":"https://kidztherapyzone.com/","target":"_blank"}},[_c(VImg,{attrs:{"src":"ktzlogo.png","height":"140","contain":""}})],1)])],1)],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"blue darken-3",attrs:{"dark":""}},[_c(VCardSubtitle,[_c('p',{staticClass:"font-weight-regular white--text"},[_vm._v("We are grateful for our partnership with Kidz Therapy Zone, a leading organization in providing high-quality therapeutic services to children. Together, we're committed to helping children progress in their development by using AI-generated images and chats.")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }