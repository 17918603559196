import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VSystemBar,{attrs:{"color":"blue darken-3"}}),_c(VAppBar,{staticClass:"flex-grow-0",attrs:{"color":"primary","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}}),_c(VToolbarTitle,{on:{"click":function($event){return _vm.$router.push('/')}}},[_c('span',{staticClass:"font-weight-light white--text",staticStyle:{"font-size":"25px"}},[_vm._v("Therapy")]),_c('span',{staticStyle:{"margin-left":"10px"}}),_c('span',{staticClass:"font-weight-light white--text",staticStyle:{"font-size":"25px"}},[_vm._v("Images")]),_c('span',{staticStyle:{"margin-left":"10px"}}),_c('span',{staticClass:"white--text",staticStyle:{"font-size":"15px"}},[_vm._v("by")]),_c('span',{staticStyle:{"margin-left":"10px"}}),_c('span',{staticClass:"bold white--text",staticStyle:{"font-size":"25px"}},[_vm._v("AI")])]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"yellow"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":"","to":"contactus"}},[_c(VListItemTitle,[_vm._v("Contact Us")])],1)],1)],1)],1),_c(VNavigationDrawer,{staticClass:"primary",attrs:{"dark":"","temporary":"","absolute":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6"},[_vm._v(" Exercise Decks ")])],1)],1),_vm._l((_vm.navList),function(item,i){return _c(VListItem,{key:i,attrs:{"link":"","to":item.route}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)})],2)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }