<template>  
    <v-app>  
      <v-container>
        <v-row justify="center" class="mt-4">  
          <v-col cols="12" sm="8" md="6">  
            <a href="https://kidztherapyzone.com/" target="_blank">
              <v-img src="ktzlogo.png" height="140" contain></v-img>  
            </a>
          </v-col>  
        </v-row>  
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card class="blue darken-3" dark>
              <v-card-subtitle>
                <p  class="font-weight-regular white--text">We are grateful for our partnership with Kidz Therapy Zone, a leading organization in providing high-quality therapeutic services to children. Together, we're committed to helping children progress in their development by using AI-generated images and chats.</p>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- <Confirm v-if="showConfirm" @OK="showConfirm = false"/>/> -->
    </v-app>  
  </template>  
    
  <script>  
  // import Confirm from "@/components/Confirm.vue"

    export default {  
      components:{
        // Confirm
      },
      data(){
        return{
          showConfirm: true
        }
      }
      // Component code  
    }  
  </script>  
  