import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialogConfirmation),callback:function ($$v) {_vm.dialogConfirmation=$$v},expression:"dialogConfirmation"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("Confirm")]),_c(VCardText,[_c('div',{staticClass:"text-h7 mx-12 my-6"},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),(_vm.showDescription)?_c(VTextarea,{staticClass:"mx-12",attrs:{"outlined":"","label":"Add other issues to report..."},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}):_vm._e(),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"mb-5 mr-6",attrs:{"color":"primary"},on:{"click":_vm.closeCancel}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"mb-5 mr-6",attrs:{"color":"primary"},on:{"click":_vm.takeAction}},[_vm._v(_vm._s(_vm.btnLabel))])],1)],1)],1)],1)],1),_c(VDialog,{model:{value:(_vm.showProgress),callback:function ($$v) {_vm.showProgress=$$v},expression:"showProgress"}},[_c('div',{staticClass:"text-center",attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"size":70,"width":7,"color":"blue","indeterminate":""}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }