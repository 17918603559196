<template>
  <home-page />
</template>

<script>
  import HomePage from '../components/HomePage'

  export default {
    name: 'Home',

    components: {
      HomePage,
    },
  }
</script>
