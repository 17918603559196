import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VImg,{staticClass:"mt-10",attrs:{"src":"under-construction.jpeg"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }